window.MORE_ENV = {
    apiEndpoint: 'https://api.moreapp.com/api/v1.0',
    apiEndpointV2: 'https://api.moreapp.com/api/v2',
    hydraPublicUrl: 'https://auth.moreapp.cloud',
    countriesEndpoint: 'https://countries.moreapp.cloud',
    featureSwitches: {},
    moreappWebsite: 'https://moreapp.com',
    platformUrlProduction: 'https://platform.moreapp.com',
    webClientUrl: 'https://app.moreapp.com',
    moreIosDownloadLink: 'https://itunes.apple.com/en/app/moreapp/id839543670',
    moreAndroidDownloadLink: 'https://play.google.com/store/apps/details?id=com.moreapp',
    freshChatToken: '3089d94a-34f4-4ba8-965f-726194a8e3de',
    betaPlatformEndpoint: 'https://moreapp-new-platform-production.web.app/',
    postHogToken: 'phc_rZELfXbsC6BpgdhfoCFJY8izgJEHDfkdD87awxHomsu',
    postHogApiHost: 'https://egel.moreapp.cloud',
    postHogUiHost: 'https://eu.posthog.com',
    environment: 'production'
};
